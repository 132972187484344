/* eslint-disable func-names */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Pie } from 'react-chartjs-2';
import url from '../../config/Url';
import imgfAcrediteSe from './img/img-acreditese.svg';
import imgfAcrediteSeShort from './img/new-sidebar/img-acreditese-short.svg';
import imgNewAcrediteSeWithTextInline from './img/acredita-se-text-inline.svg';
import imgfHome from './img/new-sidebar/icon-home.svg';
import imgfHomeDestkop from './img/new-sidebar/icon-home-desktop.svg';
import imgfHomeHover from './img/new-sidebar/icon-home-hover.svg';
import imgfModuleQuality from './img/new-sidebar/icon-module-quality-panel.svg';
import imgfModuleQualityDesktop from './img/new-sidebar/icon-module-quality-panel-desktop.svg';
import imgfModuleQualityHover from './img/new-sidebar/icon-module-quality-panel-hover.svg';
import imgfCiq from './img/new-sidebar/icon-module-ciq.svg';
import imgfCiqDesktop from './img/new-sidebar/icon-module-ciq-desktop.svg';
import imgfCiqHover from './img/new-sidebar/icon-module-ciq-hover.svg';
import imgfModuleOperational from './img/new-sidebar/icon-module-operational-panel.svg';
import imgfModuleOperationalDesktop from './img/new-sidebar/icon-module-operational-panel-desktop.svg';
import imgfModuleOperationalHover from './img/new-sidebar/icon-module-operational-panel-hover.svg';
import imgfModuleAudit from './img/new-sidebar/icon-module-audit-management.svg';
import imgfModuleAuditDesktop from './img/new-sidebar/icon-module-audit-management-desktop.svg';
import imgfModuleAuditHover from './img/new-sidebar/icon-module-audit-management-hover.svg';
import imgfModuleDocument from './img/new-sidebar/icon-module-documents.svg';
import imgfModuleDocumentDesktop from './img/new-sidebar/icon-module-documents-desktop.svg';
import imgfModuleDocumentHover from './img/new-sidebar/icon-module-documents-hover.svg';
import imgfReport from './img/new-sidebar/icon-report.svg';
import imgfReportDesktop from './img/new-sidebar/icon-report-desktop.svg';
import imgfReportHover from './img/new-sidebar/icon-report-hover.svg';
import imgfUsers from './img/new-sidebar/icon-users.svg';
import imgfUsersDesktop from './img/new-sidebar/icon-users-desktop.svg';
import imgfUsersHover from './img/new-sidebar/icon-users-hover.svg';
import imgfTeams from './img/new-sidebar/icon-teams.svg';
import imgfTeamsDesktop from './img/new-sidebar/icon-teams-desktop.svg';
import imgfTeamsHover from './img/new-sidebar/icon-teams-hover.svg';
import imgfTrash from './img/new-sidebar/icon-trash.svg';
import imgfTrashDesktop from './img/new-sidebar/icon-trash-desktop.svg';
import imgfTrashHover from './img/new-sidebar/icon-trash-hover.svg';
import imgfNoPic from './img/new-sidebar/icon-user-nopic.svg';
import imgfNoPicDesktop from './img/new-sidebar/icon-user-nopic-desktop.svg';
import imgfHamburger from './img/new-sidebar/icon-hamburger.svg';

import SideMenuGroup from './SideMenuGroup';
import SideMenuItem from './SideMenuItem';
import logout from '../../config/Logout';
import './styles/sidemenu.scss';
import { can, canAtLeast } from '../../config/Permissions';
import { oLocalStorage } from '../../config/EncodedLocalStorage';

String.prototype.trunc = function (n) {
  return this.substr(0, n - 1) + (this.length > n ? '...' : '');
};

class SideMenu extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bMobileOpen: false,
      valorAtual: 50
    };
  }

  renderModulePanel = () => {
    const { bIsOpened, cCurrentSideMenuOption } = this.props;
    const { bMobileOpen } = this.state;
    const aItems = [];
    const aReportSubitems = [];
    const bIsSubitemOpened = true;
    const cSideMenuNameClassName = 'v2-sidemenu-name';

    aItems.push(
      <SideMenuItem
        key="module-panel-item-1"
        cName={intl.get('home')}
        cIcon={bMobileOpen ? imgfHome : imgfHomeDestkop}
        cIconHover={imgfHomeHover}
        cLink="/"
        bShowName={bMobileOpen || bIsOpened}
        bShowBorder
        cSelectedMenuName={cCurrentSideMenuOption}
        cSideMenuNameClassName={cSideMenuNameClassName}
      />
    );

    if (canAtLeast('quality-panel', 'quality-panel')) {
      aItems.push(
        <SideMenuItem
          key="module-panel-item-2"
          cName={intl.get('Nav.quality_panel')}
          cIcon={bMobileOpen ? imgfModuleQuality : imgfModuleQualityDesktop}
          cIconHover={imgfModuleQualityHover}
          cLink="/quality-panel"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }
    if (canAtLeast('audit-management', 'audit-management')) {
      aItems.push(
        <SideMenuItem
          key="module-panel-item-3"
          cName={intl.get('Nav.audit_management')}
          cIcon={bMobileOpen ? imgfModuleAudit : imgfModuleAuditDesktop}
          cIconHover={imgfModuleAuditHover}
          cLink="/audit-management"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }

    if (canAtLeast('operational-panel', 'operational-panel')) {
      aItems.push(
        <SideMenuItem
          key="module-panel-item-4"
          cName={intl.get('Nav.operational_panel')}
          cIcon={bMobileOpen ? imgfModuleOperational : imgfModuleOperationalDesktop}
          cIconHover={imgfModuleOperationalHover}
          cExternalLink="/dashboard"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }

    if (canAtLeast('documents', 'documents')) {
      aItems.push(
        <SideMenuItem
          key="module-panel-item-5"
          cName={intl.get('Nav.document')}
          cIcon={bMobileOpen ? imgfModuleDocument : imgfModuleDocumentDesktop}
          cIconHover={imgfModuleDocumentHover}
          cLink="/folder"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }

    if (canAtLeast('document-reading', 'documents')) {
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-1"
          cName={intl.get('Nav.reading_documents')}
          cLink="/report/reading-document"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
    }
    if (canAtLeast('pending-reading', 'documents')) {
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-2"
          cName={`${intl.get('Nav.pending_reading')} | ${intl.get('Nav.delegates_to_me')}`}
          cLink="/report/reading-document-delegate"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-3"
          cName={`${intl.get('Nav.pending_reading')} | ${intl.get('Nav.i_am_responsible')}`}
          cLink="/report/reading-document-responsible"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
    }
    if (canAtLeast('documents', 'documents')) {
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-4"
          cName={`${intl.get('Nav.pending_edits')} | ${intl.get('Nav.delegates_to_me')}`}
          cLink="/report/edits-document-delegate"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-5"
          cName={`${intl.get('Nav.pending_edits')} | ${intl.get('Nav.i_am_responsible')}`}
          cLink="/report/edits-document-responsible"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-6"
          cName={intl.get('Nav.pending_requests')}
          cLink="/report/request"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
    }
    if (canAtLeast('pending-per-user')) {
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-7"
          cName={intl.get('Nav.pending_per_user')}
          cLink="/report/pending-per-user"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
    }

    aReportSubitems.push(
      <SideMenuItem
        key="module-panel-report-menu-item-8"
        cName="Atividades Pendentes"
        cLink="/report/activities-pending"
        bShowName={bIsSubitemOpened}
        cSelectedMenuName={cCurrentSideMenuOption}
      />
    );

    if (canAtLeast('documents', 'documents') && canAtLeast('master-list')) {
      aReportSubitems.push(
        <SideMenuItem
          key="module-panel-report-menu-item-9"
          cName={intl.get('Nav.master_list')}
          cLink="/report/master-list"
          bShowName={bIsSubitemOpened}
          cSelectedMenuName={cCurrentSideMenuOption}
        />
      );
    }

    if (canAtLeast('access-ciq', 'internal-quality-control')) {
      aItems.push(
        <SideMenuItem
          key="module-panel-item-6"
          cName={intl.get('Nav.ciq')}
          cIcon={bMobileOpen ? imgfCiq : imgfCiqDesktop}
          cIconHover={imgfCiqHover}
          cExternalLink="/dashboard/ciq"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }

    aItems.push(
      <SideMenuItem
        key="module-panel-menu-item-6"
        cName={intl.get('Nav.report')}
        cIcon={bMobileOpen ? imgfReport : imgfReportDesktop}
        cIconHover={imgfReportHover}
        aSubitems={aReportSubitems}
        bShowName={bMobileOpen || bIsOpened}
        bShowBorder
        cSelectedMenuName={cCurrentSideMenuOption}
        cSideMenuItemClassName="v2-sidemenuitem-subitems-md"
        cSideMenuNameClassName={cSideMenuNameClassName}
      />
    );

    const rcmpSdMnItModules = <SideMenuGroup key="menu-module-panel" aItems={aItems} className="v2-sidemenu-top-bar" />;
    return rcmpSdMnItModules;
  };

  renderUserConfigPanel = () => {
    const { bIsOpened, cCurrentSideMenuOption } = this.props;
    const { bMobileOpen } = this.state;
    const bLowerBorder = true;
    const bUperBorder = true;
    const aItems = [];
    const cSideMenuNameClassName = 'v2-sidemenu-name';

    if (canAtLeast(['create-edit-user', 'admin'])) {
      aItems.push(
        <SideMenuItem
          key="user-config-item-1"
          cName={intl.get('Nav.users')}
          cIcon={bMobileOpen ? imgfUsers : imgfUsersDesktop}
          cIconHover={imgfUsersHover}
          cLink="/user"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }
    if (canAtLeast(['create-edit-team', 'admin'])) {
      aItems.push(
        <SideMenuItem
          key="user-config-item-2"
          cName={intl.get('Nav.teams')}
          cIcon={bMobileOpen ? imgfTeams : imgfTeamsDesktop}
          cIconHover={imgfTeamsHover}
          cLink="/team"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }
    if (can('admin')) {
      aItems.push(
        <SideMenuItem
          key="user-config-item-3"
          cName={intl.get('Nav.trash')}
          cIcon={bMobileOpen ? imgfTrash : imgfTrashDesktop}
          cIconHover={imgfTrashHover}
          cLink="/trash"
          bShowName={bMobileOpen || bIsOpened}
          bShowBorder
          cSelectedMenuName={cCurrentSideMenuOption}
          cSideMenuNameClassName={cSideMenuNameClassName}
        />
      );
    }
    const rcmpSdMnItUserConfig = (
      <SideMenuGroup
        key="menu-user-config-panel"
        aItems={aItems}
        bLowerBorder={bLowerBorder}
        bUperBorder={bUperBorder}
        className="v2-sidemenugroup-user-config"
      />
    );
    return rcmpSdMnItUserConfig;
  };

  renderUserPanel = () => {
    const { bIsOpened, cCurrentSideMenuOption, bIsUserConfigMenu, oUser } = this.props;
    const { bMobileOpen } = this.state;
    const aSubitems = [];
    const bIsSubitemOpened = true;
    const cSideMenuNameClassName = 'v2-sidemenu-name';

    aSubitems.push(
      <SideMenuItem
        key="user-panel-subitem-1"
        cName={intl.get('Nav.my_profile')}
        cLink="/my-account/my-profile"
        bShowName={bIsSubitemOpened}
      />
    );
    aSubitems.push(
      <SideMenuItem
        key="user-panel-subitem-2"
        cName={intl.get('Nav.my_companies')}
        cLink="/quality-panel-consultant"
        bShowName={bIsSubitemOpened}
      />
    );
    aSubitems.push(
      <SideMenuItem
        key="user-panel-subitem-3"
        cName={intl.get('Nav.logout')}
        // cLink="/logout"
        bShowName={bIsSubitemOpened}
        onClick={() => logout()}
      />
    );

    let imgfAvatar = bMobileOpen ? imgfNoPic : imgfNoPicDesktop;
    let cUserName = oUser.user_cname;
    if (oUser.user_cavatar === null) {
      const cAvatarPath = oLocalStorage.get('cUserAvatar');
      if (cAvatarPath !== null && cAvatarPath !== 'null') imgfAvatar = cAvatarPath;
    } else {
      imgfAvatar = oUser.user_cavatar;
    }

    if (cUserName === '') {
      cUserName = oLocalStorage.get('cUserName');
      if (cUserName === null) cUserName = '';
    } else {
      cUserName = oUser.user_cname;
    }

    const rcmpSdMnItUser = (
      <SideMenuGroup
        key="menu-user-panel"
        aItems={[
          <SideMenuItem
            key="user-panel-item-1"
            cName={bMobileOpen ? cUserName : cUserName.trunc(18)}
            cIcon={imgfAvatar}
            aSubitems={aSubitems}
            bShowName={bMobileOpen || bIsOpened}
            bMobile={bMobileOpen}
            bShowBorder
            cSideMenuIconClassName="v2-sidemenuitem-user-avatar"
            cSideMenuItemClassName="v2-sidemenuitem-user-border"
            cSelectedMenuName={cCurrentSideMenuOption}
            bIsUserConfigMenu={bIsUserConfigMenu}
            cSideMenuNameClassName={cSideMenuNameClassName}
          />
        ]}
        className="v2-sidemenugroup-user"
      />
    );
    return rcmpSdMnItUser;
  };

  renderSpacePanel = () => {
    const { oUser, bIsOpened } = this.props;
    const { bMobileOpen, valorAtual } = this.state;

    let nQuotaUsed = oUser.customer.cust_nquotaused;
    let nTotalSpace = oUser.customer.nDiskSpace;

    if (nQuotaUsed === null) {
      nQuotaUsed = oLocalStorage.get('nQuotaUsed');
      if (nQuotaUsed === null) nQuotaUsed = 0;
      else {
        nQuotaUsed = Number(nQuotaUsed);
        nQuotaUsed /= 1024 * 1024 * 1024;
      }
    } else {
      nQuotaUsed /= 1024 * 1024 * 1024;
    }

    if (nTotalSpace === null) {
      nTotalSpace = oLocalStorage.get('nTotalSpace');
      if (nTotalSpace === null) nTotalSpace = 0;
      else nTotalSpace = Number(nTotalSpace);
    }
    const oData = {
      labels: ['Usado', 'Disponível'],
      datasets: [
        {
          data: [nQuotaUsed, nTotalSpace - nQuotaUsed],
          backgroundColor: ['#6CB6E3', '#FFFFFF'],
          hoverBackgroundColor: ['#6CB6E3', '#FFFFFF']
        }
      ]
    };
    const oOptions = {
      legend: { display: false },
      maintainAspectRatio: true,
      tooltips: { enabled: false },
      hover: { mode: null },
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    };

    const nWidth = bMobileOpen ? 20 : 80;
    const nHeight = bMobileOpen ? 11.75 : 47;

    const valorLimite = 100;

    function handleIncrement() {
      this.setState(prevState => ({
        valorAtual: prevState.valorAtual < 100 ? prevState.valorAtual + 10 : prevState.valorAtual
      }));
    }

    function handleDecrement() {
      this.setState(prevState => ({
        valorAtual: prevState.valorAtual > 0 ? prevState.valorAtual - 10 : prevState.valorAtual
      }));
    }

    const porcentagem = function () {
      if (nQuotaUsed === 0 && nTotalSpace === 0) {
        return Number(0 * 100).toFixed(2);
      }

      return Number((nQuotaUsed / nTotalSpace) * 100).toFixed(2);
    };

    const rcmpContent = (
      <>
        {bMobileOpen && (
          <div
            style={{
              width: '80%'
            }}
          >
            <div style={{ width: '100%', backgroundColor: '#e9ebee', borderRadius: '5px' }}>
              <div
                style={{
                  width: `${porcentagem()}%`,
                  height: '10px',
                  backgroundColor: '#6CB6E3',
                  borderRadius: '5px',
                  textAlign: 'center',
                  lineHeight: '20px',
                  color: 'white'
                }}
              />
            </div>
          </div>
        )}

        <div className="v2-sidemenu-space-short">
          <Pie data={oData} options={oOptions} width={nWidth} height={nHeight} />
          <p className="v2-sidemenu-short">
            {nQuotaUsed.toFixed(2)}GB/{nTotalSpace}GB
          </p>
        </div>
        {bMobileOpen || bIsOpened ? (
          <div className="v2-sidemenu-space-large-texts">
            <p className="v2-sidemenu-large">
              {nQuotaUsed.toFixed(2)} {intl.get('of').toLowerCase()} {nTotalSpace} GB {intl.get('used')}
            </p>
            <a className="v2-sidemenu-large link" href="/modules">
              {bMobileOpen ? 'Adquirir mais Armazenamento' : intl.get('get_more_storage')}
            </a>
          </div>
        ) : (
          ''
        )}
      </>
    );
    return <SideMenuGroup key="menu-space-panel" rcmpContent={rcmpContent} className="v2-sidemenugroup-space" />;
  };

  render = () => {
    const { fnMenuOpenEvent, bIsOpened, oUser } = this.props;
    const { bMobileOpen } = this.state;
    const aGroups = [];

    const rcmpHamburgerMenu = (
      <img
        src={imgfHamburger}
        alt="Abrir menu"
        className="v2-sidemenu-hamburger"
        onClick={evtClick => {
          this.setState({
            bMobileOpen: true
          });
        }}
      />
    );

    let imgfAvatar = bMobileOpen ? imgfNoPic : imgfNoPicDesktop;
    let cUserName = oUser.user_cname;
    if (oUser.user_cavatar === null) {
      const cAvatarPath = localStorage.getItem('cUserAvatar');
      if (cAvatarPath !== null && cAvatarPath !== 'null') imgfAvatar = cAvatarPath;
    } else {
      imgfAvatar = oUser.user_cavatar;
    }

    if (cUserName === '') {
      cUserName = localStorage.getItem('cUserName');
      if (cUserName === null) cUserName = '';
    } else {
      cUserName = oUser.user_cname;
    }

    let logo = '';

    if (bMobileOpen === false && bIsOpened === false) {
      logo = imgfAcrediteSeShort;
    } else if (bMobileOpen === false && bIsOpened) {
      logo = imgfAcrediteSe;
    } else if (bMobileOpen && bIsOpened === false) {
      logo = imgNewAcrediteSeWithTextInline;
    }

    const rcmpLogoContent = (
      <>
        <a href="/" style={{ display: 'inline-block' }} className="v2-link-logo">
          <img src={logo} alt="Acredite-se" className="v2-sidemenu-logo" />
        </a>
        <div className="v2-sidemenu-user">
          <div className="v2-box-icon">
            <img src={imgfAvatar} alt="" />
          </div>
          <p className="v2-cname">{cUserName}</p>
        </div>
        <div className="v2-sideborder">
          <div>&nbsp;</div>
        </div>
      </>
    );

    const rcmpSdMnGrpLogo = (
      <>
        <SideMenuGroup key="menu-logo" className="v2-sidemenugroup-logo" rcmpContent={rcmpLogoContent} oUser={oUser} />
      </>
    );
    const cIconClassName = bIsOpened ? 'icon icon-prev' : 'icon icon-next';
    const cSidemenuClasses = bMobileOpen ? 'v2-sidemenu sidemenu-open' : 'v2-sidemenu';

    // aGroups.push(rcmpHamburgerMenu);
    aGroups.push(rcmpSdMnGrpLogo);
    aGroups.push(this.renderModulePanel());
    aGroups.push(this.renderUserConfigPanel());

    // eslint-disable-next-line no-unused-expressions
    bMobileOpen ? '' : aGroups.push(this.renderUserPanel());
    aGroups.push(this.renderSpacePanel());

    const handleCloseMenu = target => {
      if (target.className.includes('v2-sidemenu sidemenu-open')) {
        this.setState({
          bMobileOpen: !bMobileOpen
        });
      }
    };

    return (
      <>
        {rcmpHamburgerMenu}
        <div className={cSidemenuClasses} onClick={ev => handleCloseMenu(ev.target)}>
          <div className="v2-menu">{aGroups}</div>
        </div>
        <div
          className="v2-sidemenu-expand"
          role="button"
          tabIndex={0}
          onClick={() => fnMenuOpenEvent()}
          onKeyPress={() => fnMenuOpenEvent()}
        >
          <i className={cIconClassName} />
        </div>
        <div className="v2-sidemenu-extraspace">&nbsp;</div>
      </>
    );
  };
}

SideMenu.defaultProps = {
  cCurrentSideMenuOption: '',
  bIsUserConfigMenu: false,
  oUser: {
    user_cname: '',
    user_cavatar: null,
    customer: {
      cust_nquotaused: null,
      nDiskSpace: null
    }
  }
};
export default SideMenu;
