import _ from 'lodash';
import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export default function getFolders(nFolderId, nSize, nQtyFolders, nQtyDocuments, cOrderBy, cOrderType) {
  return Axios.get('/folder', {
    params: {
      nFolderId,
      nSize,
      nQtyFolders,
      nQtyDocuments,
      cOrderBy,
      cOrderType
    }
  }).then(
    (oResponse) =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oFolder.fldr_nid)
          .then((aBreadcrumbFolders) => {
            resolve({
              "aFolders": oResponse.data.aFolders,
              "aDocuments": _.toArray(oResponse.data.aDocuments),
              "oFolder": oResponse.data.oFolder,
              "nTotal": oResponse.data.nTotal,
              aBreadcrumbFolders
            });
          })
          .catch((oError) => reject(oError));
      })
  );
}
