import React, { Component } from 'react';
import { Input } from 'reactstrap';
import '../styles/block.scss';
import Block from './Block';

export class ModuleBlock extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bEnabled: oProps.bStartEnabled
    };
  }

  changeStatus = () => {
    const { fnOnClick } = this.props;
    const { bEnabled } = this.state;
    fnOnClick();
    this.setState({
      bEnabled: !bEnabled
    });
  };

  render() {
    const { imgfIcon, cName, cDescription, fnOnClick } = this.props;
    const { bEnabled } = this.state;

    return (
      <div>
        <Block
          imgfIcon={imgfIcon}
          cName={cName}
          cDescription={cDescription}
          fnOnClick={this.changeStatus}
          cExtraClass={bEnabled ? 'enabled' : ''}
        >
          <div className="block-check">
            <Input type="checkbox" className="block-check-checkbox" defaultChecked={bEnabled} />
          </div>
        </Block>
      </div>
    );
  }
}

ModuleBlock.defaultProps = {
  bEnabled: false
};

export default ModuleBlock;
