import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import imgfGroup from '../../assets/img/group.svg';
import imgfNoPic from '../../assets/img/nopic.svg';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import { Alert } from '../../components/Alert';
import PageClean from '../default-v2/PageClean';
import './styles/folder.scss';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class Folder extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nId: oProps.nId,
      oFolder: {},
      aAccessControl: [],
      cSearch: ''
    };
  }

  componentDidMount() {
    const { nId } = this.state;
    const { bNewFolder, history } = this.props;
    checkModule(history, 'documents');

    if (bNewFolder || bNewFolder == undefined) {
      Axios.get('/folder/form/create')
        .then(oResponse => {
          this.setState({
            aAccessControl: oResponse.data.aAccessControl,
            bLoading: false
          });
        })
        .catch(oError => {
          this.setState({
            bLoading: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    } else {
      Axios.get(`/folder/${nId}`)
        .then(oResponse => {
          this.setState({
            oFolder: oResponse.data.oFolder,
            aAccessControl: oResponse.data.aAccessControl,
            bLoading: false
          });
        })
        .catch(oError => {
          this.setState({
            bLoading: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  }

  handleChange = evt => {
    const { oFolder } = this.state;
    if (evt.target.value !== null) {
      oFolder[evt.target.name] = evt.target.value;
      this.setState({
        oFolder
      });
    }
  };

  handleChangeAccess = (evt, oMember) => {
    const { aAccessControl } = this.state;

    const nMemberIndex = aAccessControl.findIndex(oFindingMember =>
      oMember.nUserId ? oMember.nUserId === oFindingMember.nUserId : oMember.nTeamId === oFindingMember.nTeamId
    );

    aAccessControl[nMemberIndex].bWrite = evt.target.value === 'write';

    this.setState({ aAccessControl });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({
      bIsSending: true
    });

    const { oFolder, aAccessControl, nId } = this.state;
    const { bNewFolder, onSuccess } = this.props;
    const oLocalStorage = new EncodedLocalStorage();
    let nUserId = oLocalStorage.get('nUserId');

    if (bNewFolder || bNewFolder == undefined) {
      Axios.post(`folder`, {
        fldr_cname: oFolder.fldr_cname,
        fldr_nid_parent: nId,
        user_nid: nUserId,
        aAccessControl: aAccessControl
      })
        .then(() => {
          onSuccess(intl.get('Folder.mensagem_criado'));
        })
        .catch(oError => {
          this.setState({
            bIsSending: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    } else {
      Axios.put(`folder/${oFolder.fldr_nid}`, {
        fldr_cname: oFolder.fldr_cname,
        aAccessControl: aAccessControl
      })
        .then(() => {
          onSuccess(intl.get('Folder.confirmacao_alteracao'));
        })
        .catch(oError => {
          this.setState({
            bIsSending: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elSubmenu = elWrapperRef;
    if (elSubmenu.getElementsByClassName('searchBox')[0]) {
      elSubmenu.getElementsByClassName('searchBox')[0].style = 'display: none';
      this.setState({ focused: false });
    }
  };

  onFocusSearch = () => {
    this.setState({ focused: true });
  };

  addMember = oMember => {
    const { aAccessControl, oFolder } = this.state;

    const nMemberIndex = aAccessControl.findIndex(oFindingMember =>
      oMember.nUserId ? oMember.nUserId === oFindingMember.nUserId : oMember.nTeamId === oFindingMember.nTeamId
    );

    if (oFolder.fldr_nid === null) {
      aAccessControl[nMemberIndex].bWrite = true;
    }

    aAccessControl[nMemberIndex].bRead = true;

    this.setState({ aAccessControl });
  };

  removeMember = oMember => {
    const { aAccessControl } = this.state;

    const nMemberIndex = aAccessControl.findIndex(oFindingMember =>
      oMember.nUserId ? oMember.nUserId === oFindingMember.nUserId : oMember.nTeamId === oFindingMember.nTeamId
    );

    aAccessControl[nMemberIndex].bRead = false;

    this.setState({ aAccessControl });
  };

  avatar = oMember => {
    let imgfSrc = oMember.nUserId ? imgfNoPic : imgfGroup;

    if (oMember.cAvatar) {
      imgfSrc = `${oMember.cAvatar}`;
    }

    return <img src={imgfSrc} alt={oMember.cName} />;
  };

  renderAvailableMembers = () => {
    const { focused, aAccessControl, cSearch } = this.state;

    const aItems = aAccessControl
      .filter(oMember => !oMember.bRead)
      .filter(oItem => oItem.cName !== null && oItem.cName.toLowerCase().indexOf(cSearch.toLowerCase()) !== -1);

    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <div
          style={{ display: focused === true ? 'block' : 'none' }}
          className={`searchBox ${aItems.length === 0 ? 'notfound' : ''}`}
          id="searchResult"
        >
          <ul className="list-group">
            {aItems.map((oItem, itemIndex) => (
              <li
                className={`list-group-item ${!oItem.bRegistered && oItem.user_nid ? 'pending' : ''}`}
                key={oItem.user_nid ? `item-${itemIndex}-u-${oItem.user_nid}` : `item-${itemIndex}-e-${oItem.team_nid}`}
              >
                {this.avatar(oItem)}
                <p className="infos">
                  <b>{oItem.cName}</b> <br /> {oItem.cRole}
                </p>
                <button type="button" onClick={() => this.addMember(oItem)} className="btn btn-primary">
                  {intl.get('adicionar')}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </OutsideHandlerClick>
    );
  };

  renderSelectedMembers = () => {
    const { aAccessControl, oFolder } = this.state;
    return (
      <div id="selectedUsers">
        {aAccessControl
          .filter(oMember => oMember.bRead)
          .map(oMember => (
            <span
              key={oMember.nUserId ? `u-${oMember.nUserId}` : `t-${oMember.nTeamId}`}
              className={`userBox boxAccessControls ${!oMember.bRegistered && oMember.nUserId ? 'pending' : ''}`}
            >
              <button
                aria-labelledby="selectedUsers"
                type="button"
                onClick={() => this.removeMember(oMember)}
                className="removeUser"
              />

              {this.avatar(oMember)}
              <div>
                <p className="name" title={oMember.cName}>
                  {oMember.cName}
                </p>
                <p className="role">{oMember.cRole}</p>
              </div>
              <div className="accessControl">
                <select
                  disabled={oFolder.fldr_nid === null}
                  defaultValue={oMember.bWrite ? 'write' : 'read'}
                  onChange={evt => this.handleChangeAccess(evt, oMember)}
                >
                  <option value="read">{intl.get('Folder.can_view')}</option>
                  <option value="write">{intl.get('Folder.can_create')}</option>
                </select>
              </div>
            </span>
          ))}
      </div>
    );
  };

  render() {
    const { oFolder, bIsSending, rcmpAlertMsg, bLoading } = this.state;
    const { bNewFolder, closeModal } = this.props;
    return (
      <PageClean loading={bLoading ? 1 : 0} className="modalBody folder">
        {rcmpAlertMsg}
        <div className="clearfix" />
        <h1>
          {bNewFolder ? intl.get('Folder.titulo') : intl.get('Folder.editando_pasta')}{' '}
          {oFolder.fldr_cname ? oFolder.fldr_cname : ''}
        </h1>
        <form onSubmit={this.handleSubmit} className="form" autoComplete="off">
          {oFolder.fldr_nid !== null && oFolder.fldr_nid_parent !== null && (
            <>
              <label className="colspan">
                {intl.get('Folder.cnome')} <span className="required">*</span>
                <Input
                  disabled={bIsSending}
                  onChange={this.handleChange}
                  value={oFolder.fldr_cname || ''}
                  maxLength="250"
                  required
                  name="fldr_cname"
                />
              </label>
            </>
          )}
          <div className="containerTeam">
            <label>
              <b>{intl.get('Folder.configuracoes_acesso')}</b>
              <Input
                name="search"
                placeholder={intl.get('Folder.membros_pasta_placeholder')}
                onChange={evt => {
                  this.setState({ cSearch: evt.target.value });
                }}
                onFocus={this.onFocusSearch}
                onClick={this.onFocusSearch}
              />
              {this.renderAvailableMembers()}
            </label>

            {this.renderSelectedMembers()}
          </div>

          <div className="button-stack">
            <Button outline className="btn alternate back" onClick={closeModal}>
              {intl.get('cancelar')}
            </Button>
            <Button disabled={bIsSending} type="submit" className="btn" title={intl.get('salvar')}>
              {intl.get('salvar')}
            </Button>
          </div>
        </form>
      </PageClean>
    );
  }
}

export default Folder;
